import React from 'react'
import Header from '../Header/Header';
import './Hero.css'
  import hero_image from '../../assets/hero_image.png';
    import hero_image_back from '../../assets/hero_image_back.png';
import Heart from '../../assets/heart.png';
  import Calories from '../../assets/calories.png';
const Hero = () => {
  return (
    <div className="hero">
      <div className="left-h">
        <Header />

        <div className="the-best-ad">
          <div></div>
          <span>
            the best fitness app
          </span>

        </div>

        <div className="hero-text">
          <div>
            <span className='stroke-text' >  Shape</span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal Body</span>
          </div>
          <div >
            <span>Its imp to keep you in shape</span>

          </div>
        </div>
        {/* figures */}
        <div className="figures">
          <div> <span>+140</span> <span>Expert coaches</span> </div>
          <div> <span>+477 </span><span>Members joined</span> </div>
          <div> <span>+555 </span><span> Programs</span> </div>
        </div>

{/* hero buton */}



<div className="hero-btn">
  <buttons className="btn">Get STARTED</buttons>
  <buttons className="btn">Learn More</buttons>
</div>

      </div>
      <div className="right-h"> 
      <button className='btn'> Join Now</button>
      <div className="heart-rate">
        <img src= {Heart} alt="" />
        <span>HEart Rate</span><span> 116 BTN</span>
      </div>
      {/* hero image  */}
      <img src= {hero_image} alt="" className='hero_image' />
      <img src={ hero_image_back} alt="" className='hero_image_back' />
      {/* calories */}
      <div className="calories"> 
      <img src={Calories} alt="" />
      <div>
      <span>Calories Burn</span><span>220 kcal</span>
      </div>
      </div>
       

      </div>
    </div>
  );
};

export default Hero;
