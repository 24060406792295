import React from 'react'
import './Header.css'
 import { Link } from 'react-scroll'
import logo from  '../../assets/logo.png'
const Header = () => {
  return (
   <div className="header">
   <img className='logo' src={logo} alt="" />
   <ul className='header-menue'>
    <li>Home</li>
    <li>About us</li>
    <li>Programs</li>
    <li>why us</li>
    <li>testimonials</li>
   </ul>
   </div>
  )
}

export default Header;
